<template>
    <v-card min-height="174">
        <v-select v-model="selectedDateRange" :items="dateRanges" class="px-3" color="primary" hide-details
                  return-object/>
        <v-card-title>
            {{
                isInspectionsAnalytic ? $vuetify.lang.t('$vuetify.pages.dashboard.totalInspections')
                    : $vuetify.lang.t('$vuetify.pages.dashboard.websiteVisitors')
            }}
        </v-card-title>
        <v-progress-circular v-if="loading" indeterminate/>
        <v-card-text v-else class="text-h3">
            {{ isInspectionsAnalytic ? item.total_inspections : '#' }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "KurccAnalyticsWithFixedDateRange",
    props: {
        analyticType: {
            type: String,
            validator: function (value) {
                return ['inspections', 'visitors'].indexOf(value) !== -1
            }
        }
    },
    data() {
        return {
            loading: false,
            item: [],
            selectedDateRange: null,
            fromDate: undefined,
            toDate: undefined,
        }
    },
    computed: {
        isInspectionsAnalytic() {
            return this.analyticType === 'inspections'
        },
        dateRanges() {
            return [
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.dashboard.all'),
                    value: {value: undefined, unit: undefined}
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.dashboard.today'),
                    value: {value: 0, unit: 'day'}
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.dashboard.thisWeek'),
                    value: {value: 1, unit: 'week'}
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.dashboard.thisMonth'),
                    value: {value: 1, unit: 'month'}
                },
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.dashboard.lastThreeMonths'),
                    value: {value: 3, unit: 'month'}
                },
            ]
        }
    },
    watch: {
        selectedDateRange(val) {
            if (val === this.dateRanges[0]) {
                this.toDate = undefined
                this.fromDate = undefined
            } else {
                this.toDate = this.$dayjs().toISOString()
                this.fromDate = this.$dayjs().subtract(val.value.value, val.value.unit).toISOString()
            }
            this.getInspectionsAnalytics()
        }
    },
    methods: {
        getInspectionsAnalytics() {
            this.loading = true
            this.$store.dispatch('getInspectionsAnalytics', {from: this.fromDate, to: this.toDate}).then(item => {
                this.item = item
                this.loading = false
            })
        }
    },
    created() {
        this.selectedDateRange = this.dateRanges[0]

        if (this.isInspectionsAnalytic)
            this.getInspectionsAnalytics()
        else // TODO change this to use "Website Visitors" API
            this.getInspectionsAnalytics()
    }
}
</script>
